
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { DocumentFolder, DocumentType } from "@/store/modules/document.store";
import { FormFile } from "@/components/forms/index";

@Component({
    components: {
        FormFile,
        documentCard: () => import("@/components/document/card.vue"),
    },
})
export default class DocumentPlaceholder extends Vue {
    @Prop() placeholder!: DocumentType;
    @Prop() folder!: DocumentFolder;
    @Prop({ default: "Hubr" }) theme!: "Hubr" | "My Hubr";

    @Ref() fileUpload!: HTMLElement;

    dragging = false;

    dragStart() {
        this.dragging = true;
    }
    dragEnd() {
        this.dragging = false;
    }

    onDrop(e: any) {
        this.dragging = false;
        // @ts-ignore
        this.fileUpload.handleFileChange(e);
    }
}
